<template>
  <div class="text-right my-2 mx-1">
    <Menu @click="toggleMenu"  as="div" class="relative inline-block text-left w-full">
      <MenuButton  class="inline-flex w-full justify-center rounded-sm bg-black bg-opacity-20 px-5 py-3 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        <div v-if="menuOpen" class="mr-2"><font-awesome-icon icon="fa-solid fa-angle-up" /></div>
        <div v-else class="mr-2"><font-awesome-icon icon="fa-solid fa-angle-down" /></div>
        {{ label }}
      </MenuButton>
      <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
        <MenuItems class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-sm bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="px-1">
            <MenuItem as="div" class="my-1" v-for="item in items" v-slot="{ active }">
              <button v-if="item == label" @click="selectItem(item)" class="bg-gray-700 text-white group flex w-full items-center rounded-sm p-2 text-xs">{{ item }}</button>
              <button v-else @click="selectItem(item)" :class="[active ? 'bg-gray-700 text-white' : 'text-gray-900', 'group flex w-full items-center rounded-sm p-2 text-xs']">{{ item }}</button>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default {
  name: "DropDown",
  components: {Menu, MenuButton, MenuItems, MenuItem},
  props: ['label', 'items'],
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    selectItem: function(item) {
      this.$emit("valueSelected",item);
    },
    toggleMenu: function() {
      this.menuOpen = !this.menuOpen
    }
  }
}
</script>