import { createStore } from 'vuex';
import api from "@/js/api";
const store = createStore({
    state() {
        return {
            apiData: {
                fileList: [],
                states: [],
                stages: [],
                sellingSidePlatforms: [],
                revenue: [],
                events: [],
                currencyConversion: []
            },
            alert: {
                showAlert: false,
                isError: false,
                alertText: ""
            },
            statusIndicator: {
                indicateLoading: false,
                indicateError: false
            },

        };
    },
    getters: {
        getSellingSidePlatform: (state) => (sspName) => {
            return state.apiData.sellingSidePlatforms.filter(ssp => ssp.ssp_name == sspName)[0]
        },
        getSellingSidePlatformsForMonth: (state) => (year, month) => {
            let sspsToInclude = state.apiData.states.filter(
                s => s.acst_year === parseInt(year) && s.acst_month === parseInt(month)
            ).map(s => s.ssp_cntr)
            return state.apiData.sellingSidePlatforms.filter(ssp => sspsToInclude.includes(ssp.ssp_cntr)).sort((a, b) => a.ssp_name > b.ssp_name)
        },
        getStates: (state) => (year, month, ssp_cntr) => {
            return state.apiData.states.filter(
                st => st.acst_year == year &&
                    st.acst_month == month &&
                    st.ssp_cntr == ssp_cntr
            )
        },
        getStage: (state) => (acsg_cntr) => {
            return state.apiData.stages.filter(sg => sg.acsg_cntr === acsg_cntr)[0]
        },
        getStageByLabel: (state) => (acsg_label) => {
            return state.apiData.stages.filter(sg => sg.acsg_label === acsg_label)[0]
        },
        getStages: (state) => () => {
            return state.apiData.stages // TODO filter by applicable for year and month
        },
        getRevenueForSSP: (state) => (year, month, sspName, source) => {
            return state.apiData.revenue.filter(
                r => r.rev_year === parseInt(year) && r.rev_month === parseInt(month) && r.rev_ssp === sspName && r.rev_source === source
            ).reduce((partialSum, rev) => partialSum + rev.rev_n2, 0) // Sum up multiple results, possible with multiple connectionTypes
        },
        getRevenueForConnection: (state) => (year, month, sspName, connectionName, source) => {
            return state.apiData.revenue.filter(
                r => r.rev_year === parseInt(year) && r.rev_month === parseInt(month) && r.rev_ssp === sspName && r.rev_source === source && r.rev_connectionType === connectionName
            ).reduce((partialSum, rev) => partialSum + rev.rev_n2, 0) // Sum up multiple results, possible with multiple connectionTypes
        },
        getRevenueForAllConnections: (state) => (year, month, sspName, source) => {
            return state.apiData.revenue.filter(
                r => r.rev_year === parseInt(year) && r.rev_month === parseInt(month) && r.rev_ssp === sspName && r.rev_source === source
            )
        },
        getConnectionNamesForSellingSidePlatform: (state) => (sspName) => {
            return state.apiData.revenue.filter(
                r => r.rev_ssp === sspName
                ).map(r => r.rev_connectionType[0])
                .filter((value, index, array) => array.indexOf(value) === index); // get distinct values
        },
        getYears: (state) => () => {
            return state.apiData.states
                .map(st => st.acst_year) // extract years
                .filter((value, index, array) => array.indexOf(value) === index); // get distinct values
        },
        getMonths: (state) => (year) => {
            return state.apiData.states
                .filter(st => st.acst_year === parseInt(year)) // filter to selected year
                .sort(function(first, second) {return first.acst_month - second.acst_month}) // sort numerically
                .map(st => api.monthNames[st.acst_month - 1]) // extract months and translate to name of month
                .filter((value, index, array) => array.indexOf(value) === index); // get distinct values
        },
        getEvents: (state) => (year, month, sspName) => {
            return state.apiData.events.filter(
                ev => ev.tle_year === parseInt(year) && ev.tle_month === parseInt(month) && ev.tle_ssp === sspName
            )
        },
        getCurrencyConversion: (state) => () => {
            return state.apiData.currencyConversion
        },
    },
    mutations: {
        setStates(state, data) {
            state.apiData.states = data
        },
        setStages(state, data) {
            state.apiData.stages = data
        },
        setSellingSidePlatforms(state, data) {
            state.apiData.sellingSidePlatforms = data
        },
        setRevenue(state, data) {
            state.apiData.revenue = data
        },
        setEvents(state, data) {
            state.apiData.events = data
        },
        setCurrencyConversion(state, data) {
            state.apiData.currencyConversion = data
        },
        updateFileList(state, data) {
            state.apiData.fileList = data
        },
        updateTimelineEvents(state, data) {
            state.apiData.timelineEvents = data
        },
        updateYears(state, data) {
            state.apiData.years = data
        },
        updateMonths(state, data) {
            let months = []
            for(let month of data) {
                months.push(api.monthNames[month - 1]);
            }
            state.apiData.months = months
        },
        updateSSPs(state, data) {
            state.apiData.SSPs = data
        },
        setIndicateLoading(state, data) {
            state.statusIndicator.indicateError = false
            state.statusIndicator.indicateLoading = data
        },
        setIndicateError(state, data) {
            state.statusIndicator.indicateError = data
        },
        showSuccessAlert(state, data) {
            state.alert.alertText = data
            state.alert.isError = false
            state.alert.showAlert = true;
            setTimeout(function() {
                state.alert.showAlert = false
            }, 3000)
        },
        showErrorAlert(state, data) {
            state.alert.alertText = data
            state.alert.isError = true
            state.alert.showAlert = true;
            setTimeout(function() {
                state.alert.showAlert = false
            }, 3000)
        }
    }
});

export default store;