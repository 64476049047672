<template>
  <transition name="alert">
    <div v-if="showAlert" :class="bgcolor" class="fixed right-5 top-5  h-14 z-50 py-4 pl-3 pr-6 text-white shadow-2xl rounded-sm border border-gray-400 text-sm text-left">
      <font-awesome-icon v-if="isError" icon="fa-triangle-exclamation" class="px-2" />
      <font-awesome-icon v-else icon="fa-square-check" class="px-2"/>
      <span v-if="isError">Fehler: {{ alertText }}</span>
      <span v-else>{{ alertText }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Alert",
  props: ["showAlert", "isError", "alertText"],
  computed: {
    bgcolor() {
      return this.isError ? "bg-[#FF0000DD]" : "bg-[#00BB00DD]"
    }
  }
}
</script>

<style scoped>
.alert-enter-active, .alert-leave-active {
  transition: opacity 0.75s ease;
}
.alert-enter-from, .alert-leave-to {
  opacity: 0;
}
</style>