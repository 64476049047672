<template>
  <div class="mt-2 mb-8">
    <span class="font-bold">Umsatzanalyse - {{ selectedSSP }}</span>
    <div class="flex my-2 h-fit justify-center">
      <div v-for="(details, source) in revenue" class="border-gray-400 border rounded-sm mx-1 w-4/5 2xl:w-56" :class="details.color">
        <div class="font-bold my-2">{{ details.displayName }}</div>
        <div class="text-3xl mt-2 mb-1">{{ formatRevenue(revenue[source].n2) ?? "0" }} €</div>
        <div v-if="isDiffGood(source)" class="text-xs pb-2 text-green-600">
          <font-awesome-icon icon="fa-square-check" class="px-2"/>
          <span v-if="source === 'INVOICE'">Beleg vorhanden</span>
          <span v-else>
           {{ (revenue[source].n2 - revenue['INVOICE'].n2 ?? 0).toFixed(2) + "€" }} zum Beleg
           ({{ this.getDiffBetween(revenue[source].n2, revenue['INVOICE'].n2 ?? 0) }}%)
          </span>
        </div>
        <div v-else class="text-xs pb-2 text-red-600">
          <font-awesome-icon icon="fa-triangle-exclamation" class="px-2" />
          <span v-if="source === 'INVOICE'">Kein Belegwert vorhanden</span>
          <span v-else-if="revenue['INVOICE'].n2 === 0">Kein Belegwert vorhanden</span>
          <span v-else-if="revenue[source].n2 === 0">-100.00% Differenz</span>
          <span v-else>
           {{ (revenue[source].n2 - revenue['INVOICE'].n2 ?? 0).toFixed(2) + "€" }} zum Beleg
           ({{ this.getDiffBetween(revenue[source].n2, revenue['INVOICE'].n2 ?? 0) }}%)
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/js/api";
export default {
  name: "RevenueView",
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  data() {
    return {
      diffThresholdEuro: 10
    }
  },
  computed: {
    revenue() {
      return {
        "INVOICE": {
          displayName: "Beleg",
          color: "bg-red-200",
          n2: this.$store.getters.getRevenueForSSP(this.selectedYear, this.selectedMonth, this.selectedSSP, "INVOICE")
        },
        "POWERBI": {
          displayName: "PowerBI",
          color: "bg-amber-200",
          n2: this.$store.getters.getRevenueForSSP(this.selectedYear, this.selectedMonth, this.selectedSSP, "POWERBI")
        },
        "POWERBICORRECTED": {
          displayName: "PowerBI (korrigiert)",
          color: "bg-amber-300",
          n2: this.$store.getters.getRevenueForSSP(this.selectedYear, this.selectedMonth, this.selectedSSP, "POWERBICORRECTED")
        },
        "NAUTILUS": {
          displayName: "Nautilus",
          color: "bg-teal-200",
          n2: this.$store.getters.getRevenueForSSP(this.selectedYear, this.selectedMonth, this.selectedSSP, "NAUTILUS"),
        }
      }
    }
  },
  async mounted() {
    await api.getRevenue()
  },
  methods: {
    formatRevenue(revenue) {
      return parseFloat(revenue).toLocaleString('de')
    },
    getDiffBetween: function(thisRevenue, targetRevenue) {
      return ((1 - (thisRevenue / targetRevenue)) * 100).toFixed(2) * - 1
    },
    isDiffGood(identifier) {
      let invoiceRevenue = this.revenue["INVOICE"].n2
      let compareRevenue = this.revenue[identifier].n2
      return Math.abs(invoiceRevenue - compareRevenue) <= this.diffThresholdEuro && invoiceRevenue != null && invoiceRevenue != 0 || (identifier === "INVOICE" && invoiceRevenue != null)
    }
  }
}
</script>