<template>
  <!-- Loading status indicator -->
  <div v-if="$store.state.statusIndicator.indicateLoading" class="fixed top-0 left-0 w-screen h-[2px] bg-green-500 opacity-95 z-50 animate-pulse"></div>
  <div v-if="$store.state.statusIndicator.indicateError" class="fixed top-0 left-0 w-screen h-[2px] bg-red-500 opacity-95 z-50"></div>
  <!-- Alert Notifications -->
  <Alert :showAlert="$store.state.alert.showAlert" :isError="$store.state.alert.isError" :alertText="$store.state.alert.alertText"/>
  <!-- App -->
  <div class="flex flex-col h-screen">
    <HeaderView :selectedYear="$route.params.year" :selectedMonth="$route.params.month" :selectedSSP=$route.params.ssp />
    <div v-if="!loginComplete" class="flex flex-row h-96 justify-center items-center text-black">
      <font-awesome-icon class="animate-spin mr-3 text-black" icon="fa-solid fa-circle-notch" /> Prüfe Berechtigung...
    </div>
    <div v-else-if="isAccessDenied" class="flex flex-row h-96 justify-center items-center">
      <WelcomePane label="Programmatic-Abrechnungsmanager (PAM) - Kein Zugriff">Willkommen im <strong>Programmatic-Abrechnungsmanager</strong>! Dein Unternehmenskonto hat keinen Zugriff auf diese Anwendung!</WelcomePane>
    </div>
    <div v-else-if="isLoggedIn" class="">
      <SideMenu class="fixed bg-gray-200" :selectedYear="$route.params.year" :selectedMonth="$route.params.month" :selectedSSP="$route.params.ssp"/>
      <DetailView class="ml-[350px]" :selectedYear="$route.params.year" :selectedMonth="$route.params.month" :selectedSSP="$route.params.ssp"/>
    </div>
    <div v-else class="flex flex-row h-96 justify-center items-center">
      <WelcomePane label="Programmatic-Abrechnungsmanager (PAM) - Nicht angemeldet">Willkommen im <strong>Programmatic-Abrechnungsmanager</strong>! Bitte melde dich mit deinem Unternehmenskonto an, um die Anwendung zu nutzen.</WelcomePane>
    </div>
  </div>
</template>

<script>
import HeaderView from "@/components/segments/Header.vue";
import DetailView from "@/components/segments/SSPView.vue";
import WelcomePane from "@/components/reusable/WelcomePane.vue";
import SideMenu from "@/components/segments/SideMenu.vue";
import Alert from "@/components/reusable/Alert.vue";
import api from "@/js/api";

export default {
  name: 'App',
  components: {Alert, SideMenu, WelcomePane, DetailView, HeaderView},
  data() {
    return {
      isLoggedIn: false,
      isAccessDenied: false,
      loginComplete: false
    };
  },
  created() {
    // Watch route change to set page title
    this.$watch(
      () => [this.$route.params.year, this.$route.params.month], (toParams) => {
        window.document.title = "PAM: Programmatic Abrechnungsmanager - " + this.$route.params.year + "-" + this.$route.params.month.toString().padStart(2, "0")
      }
    )
    window.document.title = "PAM: Programmatic Abrechnungsmanager - " + this.$route.params.year + "-" + this.$route.params.month.toString().padStart(2, "0")

    this.$emitter.on('login', (account) => {
      this.isLoggedIn = true;
    })
    this.$emitter.on('logout', () => {
      this.isLoggedIn = false;
    });
    this.$emitter.on('accessDenied', () => {
      this.isAccessDenied = true;
    })
    this.$emitter.on('ssoLoadComplete', () => {
      this.loginComplete = true;
    })
  }
}
</script>

<style>
body {
  background-color: rgb(156 163 175 / 0.25);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
