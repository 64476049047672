<template>
  <div>
    <StageView :selectedYear="selectedYear" :selectedMonth="selectedMonth" :selectedSSP="selectedSSP"/>
    <hr class="border-gray-400 border-1 mt-4 mx-4"/>
    <div class="flex 2xl:justify-center">
      <div class="w-[800px] 2xl:w-fit">
        <RevenueView :selectedYear="selectedYear" :selectedMonth="selectedMonth" :selectedSSP="selectedSSP"/>
        <TimelineView :selectedYear="selectedYear" :selectedMonth="selectedMonth" :selectedSSP="selectedSSP"/>
      </div>
      <div class="h-[calc(100vh-225px)] bg-gray-400 w-[1px] mx-5"></div>
      <div class="flex flex-col">
        <ActionView @openOverlay="showImportOverlay=true" :selectedYear="selectedYear" :selectedMonth="selectedMonth" :selectedSSP="selectedSSP"/>
        <FileView :selectedYear="selectedYear" :selectedMonth="selectedMonth" :selectedSSP="selectedSSP"/>
        <Notepad :selectedSSP="selectedSSP"/>
      </div>
    </div>
    <!-- Invoice Import Overlay -->
    <ImportOverlay v-show="showImportOverlay" @closeOverlay="showImportOverlay=false" :selectedYear="selectedYear" :selectedMonth="selectedMonth" :selectedSSP="selectedSSP"/>
  </div>

</template>

<script>
  import StageView from "@/components/segments/SSPView/Stages.vue";
  import FileView from "@/components/segments/SSPView/FileArea.vue";
  import RevenueView from "@/components/segments/SSPView/Revenue.vue";
  import TimelineView from "@/components/segments/SSPView/Timeline.vue";
  import ActionView from "@/components/segments/SSPView/Actions.vue";
  import ImportOverlay from "@/components/segments/SSPView/ImportOverlay.vue";
  import Notepad from "@/components/segments/SSPView/Notepad";
  export default {
    name: 'DetailView',
    props: ["selectedYear", "selectedMonth", "selectedSSP"],
    components: {Notepad, ActionView, RevenueView, FileView, StageView, TimelineView, ImportOverlay},
    data() {
      return {
        showImportOverlay: false
      }
    }
  }
</script>