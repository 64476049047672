<template>
  <div class="h-16 w-full bg-gray-700 flex z-40">
    <div class="flex flex-col my-1 mx-10">
      <span class="text-white text-2xl">PAM</span>
      <span class="text-white text-xs">Programmatic-Abrechnungsmanager</span>
    </div>
    <div class="flex">
      <DropDownView :label=selectedYear :items=$store.getters.getYears() @valueSelected="selectYear"/>
      <DropDownView :label="api().monthNames[selectedMonth - 1]" :items=$store.getters.getMonths(selectedYear) @valueSelected="selectMonth"/>
    </div>
    <div class="flex-grow" />
    <!--<button @click="processPowerBI" class="text-white text-xs bg-gray-800 hover:bg-gray-900 border-1 border-white py-1 px-4 my-2 ml-2">PowerBI-Verarbeitung starten</button>-->
    <button @click="createMonthlyExport" class="text-white text-xs bg-gray-800 hover:bg-gray-900 border-1 border-white py-1 px-4 my-2 ml-2">Monatsexport herunterladen</button>
    <UserInfo/>
  </div>
</template>

<script>
import UserInfo from "@/components/reusable/UserInfo.vue";
import DropDownView from "@/components/reusable/DropDown.vue";
import storage from "@/js/storage";
import api from "@/js/api";
import store from "@/store";
export default {
  name: "HeaderView",
  components: {DropDownView, UserInfo},
  props: ["selectedYear", "selectedMonth", "selectedSSP"],
  methods: {
    api() {return api},
    selectYear: function(value) {
      this.$router.push("/" + value  + "/" + parseInt(this.selectedMonth) + "/AdDefend")
    },
    selectMonth: function(value) {
      let monthNum = api.monthNames.indexOf(value)
      this.$router.push("/" + this.selectedYear  + "/" + (monthNum + 1) + "/AdDefend")
    },
    processPowerBI: function() {
      if (confirm("PowerBI-Datenverarbeitung jetzt starten? (Dauer ~10 Minuten)")) {
        let vm = this
        api.postADBJobRun().then(function() {
          vm.$store.commit("showSuccessAlert", "PowerBI-Datenverarbeitung wurde gestartet!")
        })
      }
    },
    createMonthlyExport: async function() {
      let monthKey = this.selectedYear + this.selectedMonth.padStart(2, "0");
      let vm = this
      await storage.loadFile(
        "exportFiles/" + monthKey + "/zip/SSPExport_" + monthKey + ".zip"
      ).catch(function (error) {
        vm.$store.commit("showErrorAlert", "Monatsexport konnte nicht erzeugt werden!")
      })
    }
  }
}
</script>